import React from 'react'
import { AuthUserContext } from '../Session'
import { withFirebase } from '../Firebase'

import { BlancheLogoRed } from '../Icons'
import { LanguageSwitcher } from '../Navigation'

import BlancheLogoWhite from '../../imgs/white-logo.png'
import AppStoreImg from '../../imgs/appstore-dw-icon.png'
import GooglePlayImg from '../../imgs/google-play-dw-icon.png'

import { useTranslation } from 'react-i18next'

const ThankyouPage = () => {
  const { t } = useTranslation()

  return (
    <AuthUserContext.Consumer>
      {authUser =>
        <div className={authUser ? 'accountBox' : 'landingPage'}>
          {!authUser && (
            <div className='centerContent mobile_hide'>
              <div style={{ width: '100%' }}>
                <BlancheLogoRed className="logo" to={window.location.origin + '/payment'} />

                <LanguageSwitcher list={true} />
              </div>
            </div>
          )}

          <div className='sectionContent' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '350px', width: '540px', maxWidth: '100%', borderRadius: 0, background: '#f9e2ed', }}>
            <div>
              {!authUser && (
                <>
                  <a href={window.location.origin + '/payment'}>
                    <img src={BlancheLogoWhite} alt="" className="logo mobile_show" style={{ marginBottom: '40px' }} />
                  </a>

                  <div className="mobile_show">
                    <LanguageSwitcher list={true} />
                    <p></p>
                  </div>
                </>
              )}

              <h1 className='sectionTitle' style={{ fontSize: '2.8rem', fontWeight: 500, marginBottom: '0' }}>{t('thankyou_page_heading')}{/* <span style={{ fontFamily: 'arial' }}>!</span> */}</h1>

              <h3 className='subHeading' style={{ fontSize: '1.8rem', marginTop: '10px', marginTop: '20px', marginBottom: '30px', lineHeight: 'normal' }}>
                <span style={{ fontWeight: 900 }}>{t('Starta om appen')}</span>{" "}
                {t('nu om den redan är installerad för att aktivera abonnemanget')}</h3>

              <h3 className='subHeading' style={{ fontSize: '1.4rem', marginTop: '10px', marginBottom: '30px', lineHeight: 'normal' }}>{t('Klicka nedan för att ladda ner appen')}</h3>

              <p style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                <a
                  href='https://play.google.com/store/apps/details?id=com.blanchestories.blanchestories&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                  className='icon'
                >
                  <img src={GooglePlayImg} alt="" style={{ width: 'auto', maxWidth: '100%', height: '50px' }} />
                </a>
                <a
                  href='https://apps.apple.com/us/app/blanchestories/id1539883289?itsct=apps_box_badge&itscg=30200'
                  className='icon'
                >
                  <img src={AppStoreImg} alt="" style={{ width: 'auto', maxWidth: '100%', height: '50px' }} />
                </a>
              </p>
            </div>
          </div>
        </div>
      }
    </AuthUserContext.Consumer>
  )
}

export default withFirebase(ThankyouPage)
