const linkStyle = {
  color: "#9f1c44",
  cursor: "pointer",
};

const buttonLinkStyle = {
  background: "#f9e2ed",
  border: "2px solid #9f1c44",
  cursor: "pointer",
  padding: "10px 20px",
  fontSize: "22px",
  fontWeight: 500,
};

const loginButtonStyle = {
  color: "#9f1c44",
  cursor: "pointer",
  background: "none",
  border: "none",
  fontSize: "16px",
  padding: 0,
  textDecoration: "underline",
};


const taxRates =
  process.env.REACT_APP_STAGING == "yes" ? "txr_1KMgO1Fha9hdCHBgKKmiczEn" : "txr_1Ij37xFha9hdCHBg2hIWe4vC";


const STRIPE_API_KEY = process.env.REACT_APP_STAGING == "yes" ? process.env.REACT_APP_STRIPE_TEST_API_KEY : process.env.REACT_APP_STRIPE_LIVE_API_KEY;


const plans = process.env.REACT_APP_STAGING == "yes"
  ? {
    year: "price_1PakJcFha9hdCHBgU5ncmTaz",
    month: "price_1PaDvsFha9hdCHBghAeNhInq",
  }
  : {
    year: "price_1K4nQoFha9hdCHBg2vmH8kzm",
    month: "price_1K4nPDFha9hdCHBgB7AnF434",
    quarter: "price_1NkmDkFha9hdCHBguzmwFle8",
  };

const promoCodes = process.env.REACT_APP_STAGING == "yes"
  ? {
    year: null,
    month: null,
    quarter: null,
  }
  : {
    year: "promo_1PBE4YFha9hdCHBgpcFjwWJV",
    month: "promo_1MGitXFha9hdCHBg8C4p62GR", // apply promocode 'DARE'
    quarter: 'promo_1NwlZtFha9hdCHBgB7cqIVNP', // apply promocode 'LOVE123' coupon 'Bokmässan 27kr' by default
    month1: "promo_1Pl5U0Fha9hdCHBgWSNacgCv", // apply promocode 'FASHION'
  };

/**
 * Errors object to override defulat error messages
 */
const ErrorsObj = {
  // firebase login
  email_missing: "Email ID is required",
  "auth/email-already-in-use": "email-already-in-use",
  "auth/account-exists-with-different-credential": `Lösenordet är ogiltigt eller så har användaren inget lösenord`,
  "auth/weak-password": "Lösenordet ska vara minst 6 tecken",
  "auth/wrong-password":
    "Lösenordet är ogiltigt eller så har användaren inget lösenord",
  "auth/user-not-found":
    "Det finns ingen användarpost som motsvarar denna identifierare. Användaren kan ha tagits bort",
  "auth/popup-closed-by-user":
    "Popup-fönstret har stängts av användaren innan operationen slutfördes",
  // firebase cloud
  promotion_code_missing: "Promotion code is required",
  promo_id_missing: "Promotion code id is required",
  price_id_missing: "Price ID is missing",
  success_url_missing: "Success Url param is missing",
  customer_missing: "Customer id is required",
  subscription_id_missing: "Subscription id required",
  priceid_same_lastone: "Selected plan is the same as currently running",
  // stripe
  stripe_resource_missing: "No such promotion code",
  stripe_parameter_unknown: "Received unknown parameter",
  stripe_invoice_upcoming_none: "No upcoming invoices",
  stripe_cannot_reedemed:
    "This coupon cannot be redeemed because it does not apply to anything in this order",
  promotion_code_customer_not_first_time:
    "You can not redeem this promotion code because you are an existing subscriber.",
};

// Remove attribute 'readonly' on field click
const removeReadonlyAttr = (event) => {
  event.target.removeAttribute("readonly");
};

// temporary variable to display test products for these users
const allowEmails = [
  "prakhar@dk.se",
  "prakhar1@dk.se",
  "prakhar2@dk.se",
  "ahmad@dk.se",
  "ahmad2@dk.se",
  "ahmad3@dk.se",
  "ahm2ad@dk.se",
  "ahm3ad@dk.se",
];

export {
  linkStyle,
  buttonLinkStyle,
  loginButtonStyle,
  taxRates,
  ErrorsObj,
  removeReadonlyAttr,
  allowEmails,
  STRIPE_API_KEY,
  plans,
  promoCodes
};
